@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fall {
  0% {
    transform: translateY(-10px) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}

@keyframes ripple {
  0%,
  35% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    -webkit-transform: scale(4);
    -moz-transform: scale(4);
    transform: scale(4);
    opacity: 0.3;
  }

  to {
    opacity: 0;
    -webkit-transform: scale(9);
    -moz-transform: scale(9);
    transform: scale(9);
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html {
  @apply bg-[#36031d];
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  height: 100%;
  overflow-x: hidden;
}

body > div#root {
  @apply h-full w-full shadow-lg overflow-hidden;
}

img {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

.icon {
  display: inline-flex;
  align-self: center;
}

.icon svg,
.icon img {
  height: 1em;
  width: 1em;
}

.icon.baseline svg,
.icon img {
  position: relative;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.btn {
  padding: 0.9em 1.5em;
}

.btn-transparent {
  font-size: 2.2rem;
  padding: 0px 14.4px;
}

.btn-transparent:hover {
  opacity: 0.6;
  box-shadow: 0 20px 15px -16px rgba(65, 41, 88, 0.2);
}

.card-active {
  background: #F1EFEA;
  color: #260514;
  overflow: hidden;
}

.card-comming-soon::before,
.card-on-going::before {
  position: absolute;
  width: fit-content;
  height: fit-content;
  color: white;
  top: 0;
  left: 0;
  font-size: 12px;
  background: #781444;
  z-index: 10;
  padding: 8px 20px 8px 8px;
  clip-path: polygon(100% 0, 90% 49%, 100% 100%, 0% 100%, 0% 0%);
}

.card-comming-soon::before {
  content: 'Akan Berlangsung'
}

.card-on-going::before {
  content: 'Berlangsung'
}

.card-active::after {
  content: '';
  mix-blend-mode: unset;
  position: absolute;
  width: 4rem;
  height: 4rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #8b56a06b;
  opacity: 0;
  border-radius: 100%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: ripple 1.8s ease-out infinite;
  -moz-animation: ripple 1.8s ease-out infinite;
  animation: ripple 1.8s ease-out infinite;
  -webkit-animation-delaye: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.footer {
  background: #260514;
  color: #F1EFEA;
}

.title-vb {
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
}

.title-big-prj-item {
  display: block;
  line-height: 1;
  font-size: 1.8rem;
}

.txt-big {
  font-size: 2.5rem;
  line-height: 4.6875rem;
  padding-top: 1px;
  padding-bottom: 4px;
}

.snowflake {
  position: fixed;
  top: -10px;
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
  animation: fall linear infinite;
}
